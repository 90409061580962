export const endpoints = {
  CURRENT_USER: '/api/v1/users/currentUser',
  GROUPS: '/api/v1/groups',
  GROUPS_CREATE: '/api/v1/groups:create',
  GROUPS_EDIT: '/api/v1/groups:edit',
  GROUPS_SET_QUOTA: '/api/v1/groups:setQuota',
  GROUPS_GET_PERMISSIONS: '/api/v1/groups:getGroupPermissions',
  GROUPS_GRANT_PERMISSIONS: '/api/v1/groups:grantGroupPermissions',
  GROUPS_ADD_MEMBER: '/api/v1/groups:addMember',
  GROUPS_GRANT_USER_PERMISSIONS: '/api/v1/groups:grantUserPermissions',
  GROUPS_SET_SUSPENDED: '/api/v1/groups:setSuspended',
  GROUPS_DELETE: '/api/v1/groups:delete',
};
