import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { QueryClient } from '@tanstack/react-query';

export const baseURL = '/';

export const axiosConfig: AxiosRequestConfig = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

export const client = (() => {
  const instance: AxiosInstance = axios.create(axiosConfig);

  // Add a response interceptor
  instance.interceptors.response.use(<T>(response: AxiosResponse<T>): T => response.data);

  return instance;
})();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (
          axios.isAxiosError(error) &&
          (error.code === 'ERR_NETWORK' ||
            (error.response?.status && error.response.status >= 400 && error.response.status < 500))
        ) {
          return false;
        }
        return failureCount < 3;
      },
      retryOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});
