import React from 'react';
import Login from '@components/auth/Login';
import { QueryProvider } from '@context/query-provider';

const AuthPage = () => (
  <QueryProvider>
    <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[100vh]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[400px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Войти</h1>
            <p className="text-balance text-muted-foreground">
              Пожалуйста, введите ваш логин и пароль ниже для входа в ваш аккаунт.
            </p>
          </div>
          <Login />
        </div>
      </div>
      <div className="hidden bg-muted lg:flex bg-neutral-900">
        <div className="p-24 w-[100vh] h-[100vh] flex justify-center align-middle flex-col">
          <img
            src="src/assets/logo-white.svg"
            alt="Logo"
            width="0"
            height="0"
            sizes="100vw"
            className="object-contain w-auto h-auto"
          />
          <p className="relative top-[-30px] font-thin text-[36px] text-white uppercase text-center">Кречет Админ</p>
        </div>
      </div>
    </div>
  </QueryProvider>
);

export default AuthPage;
