import toast from 'react-hot-toast';

export const handleMutationSuccess = (message: string, duration = 5000) => {
  toast.success(message, { duration });
};

export const handleMutationError = (message: string, duration = 5000) => {
  toast.error(message, { duration });
};

export const executeMutation = async <TParams, TResponse>(
  mutation: (variables: TParams) => Promise<TResponse>,
  params: TParams,
  successMessage?: string,
  errorMessage?: string
) => {
  try {
    const response = await mutation(params);
    if (successMessage) handleMutationSuccess(successMessage);
    return response;
  } catch (err: unknown) {
    if (errorMessage) handleMutationError(errorMessage);
    throw err;
  }
};
