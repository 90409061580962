import Dashboard from '@components/dashboard/Dashboard';
import { QueryProvider } from '@context/query-provider';
import React, { ReactNode } from 'react';

const DashboardLayout = ({ children }: { children: ReactNode }) => (
  <main className="App">
    <QueryProvider>
      <Dashboard>{children}</Dashboard>
    </QueryProvider>
  </main>
);

export default DashboardLayout;
