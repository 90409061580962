import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@lib/api/groups/queries/useCurrentUser';
import DashboardLayout from 'src/app/dashboard/DashboardLayout';
import { EAppRoute } from '../../constants';

export const PrivateRoute = () => {
  const { isSuccess: isUserSuccess, isPending } = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPending && !isUserSuccess) {
      navigate(EAppRoute.Login);
    }
  }, [isUserSuccess, isPending, navigate]);

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};
