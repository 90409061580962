import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@lib/axios';
import { LogoutRequest } from '../types';
import { logout } from '../queries';

export const useLogout = () =>
  useMutation({
    mutationKey: ['logout'],
    mutationFn: (data: LogoutRequest) => logout(data),
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['currentUser'] }),
        queryClient.invalidateQueries({ queryKey: ['reports'] }),
        queryClient.invalidateQueries({ queryKey: ['profilePicture'] }),
        queryClient.invalidateQueries({ queryKey: ['groups'] }),
      ]);
    },
  });
