import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@lib/axios';
import { login } from '../queries';
import { LoginRequest } from '../types';

export const useLogin = () =>
  useMutation({
    mutationKey: ['login'],
    mutationFn: (data: LoginRequest) => login(data),
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['currentUser'] }),
        queryClient.invalidateQueries({ queryKey: ['reports'] }),
        queryClient.invalidateQueries({ queryKey: ['profilePicture'] }),
        queryClient.invalidateQueries({ queryKey: ['groups'] }),
      ]);
    },
  });
