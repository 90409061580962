import { client } from '@lib/axios';
import {
  GrantGroupPermissions,
  GrantUserPermissions,
  Group,
  GroupAddMember,
  GroupCreate,
  GroupDelete,
  GroupEdit,
  GroupSetQuota,
  GroupSetSuspended,
  IGroupAddMemberResponse,
  CurrentUser,
} from './types';
import { endpoints } from './endpoints';

export const currentUser = () => client.get<undefined, CurrentUser>(endpoints.CURRENT_USER);
export const fetchGetGroups = () => client.get<undefined, Group[]>(endpoints.GROUPS);
export const fetchGroupCreate = (data: GroupCreate) => client.post<undefined, Group>(endpoints.GROUPS_CREATE, data);
export const fetchGroupEdit = (data: GroupEdit) =>
  client.post<undefined, undefined, GroupEdit>(endpoints.GROUPS_EDIT, data);
export const fetchGroupSetQuota = (data: GroupSetQuota) =>
  client.post<undefined, undefined, GroupSetQuota>(endpoints.GROUPS_SET_QUOTA, data);
export const fetchGetGroupPermissions = (data: GrantGroupPermissions) =>
  client.post<undefined, undefined, GrantGroupPermissions>(endpoints.GROUPS_GET_PERMISSIONS, data);
export const fetchGrantGroupPermissions = (data: GrantGroupPermissions) =>
  client.post<undefined, undefined, GrantGroupPermissions>(endpoints.GROUPS_GRANT_PERMISSIONS, data);
export const fetchGroupAddMember = (data: GroupAddMember) =>
  client.post<undefined, IGroupAddMemberResponse>(endpoints.GROUPS_ADD_MEMBER, data);
export const fetchGrantUserPermissions = (data: GrantUserPermissions) =>
  client.post<undefined, undefined, GrantUserPermissions>(endpoints.GROUPS_GRANT_USER_PERMISSIONS, data);
export const fetchGroupSetSuspended = (data: GroupSetSuspended) =>
  client.post<undefined, undefined, GroupSetSuspended>(endpoints.GROUPS_SET_SUSPENDED, data);
export const fetchGroupDelete = (data: GroupDelete) =>
  client.delete<undefined, undefined, GroupDelete>(endpoints.GROUPS_DELETE, data);
